@font-face{
  font-family:"helveticaneue-bold";
  src:url("./HelveticaNeue Bold.ttf");
}

:root {
  --color-1: #fff;
  --color-2: #c8c2b9;
  --color-3: #1a1713;
  --color-4: #b18e72;
  --color-5: #000;
}

h1 {
  font-weight: bold;
}
body,
html {
  background-color: var(--color-1);
  color: var(--color-3);
  font-size: 18px;
  // font-family: 'helveticaneue-bold'
  font-family: 'Nunito sans'

}

.navbar {
  background-color: var(--color-1);;
}
#headerMainMenu .menus a {
  color: var(--color-3);
  padding-right: 75px;
  font-weight: bold;
}

#headerMainMenu .menus a:hover {
  // color: white;
  text-decoration: underline;
}

#headerMainMenu .social-link a:nth-child(1) {
 padding-right: 25px;
}

#headerMainMenu .social-link a {
  color: var(--color-3);
}

#headerMainMenu .social-link a:hover {
  color: var(--color-3);
  text-decoration: none;
}
.bi {
  color: var(--color-3);
  font-size: 25px;
}
/*
Minting Section
*/
#mintingSection {
  background-color: var(--color-1);
  padding-top: 175px;
  padding-bottom: 120px;
}
#mintingSection .container .left {
  background-color: var(--color-1);
  color: var(--color-3);
  padding: 25px;
  // border: 2px solid var(--color-3);
  // box-shadow: 5px 5px black;
  // border-radius: 10px;
}
#mintingSection .image-banner {
  width: 350px;
  // border: 2px solid #000;
  // box-shadow: 10px 10px black;
  // border-radius: 10px;
}
#mintingSection .mint-message {
  margin-top: 40px;
  font-size: 30px;
} 
.mint_button {
  // display: none;
  margin-top: 20px;
  margin-left: 20px;
}
.button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: var(--color-3);
  border: none;
  // border-radius: 5px;
  // box-shadow: 0 9px #999;
}

.button:hover {background-color: var(--color-5)}

.button:active {
  background-color: var(--color-5);
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
/*
Collabs Section
*/
#collabSection {
  padding-top: 50px;
  padding-bottom: 70px;
}

#collabSection .title {
  padding-bottom: 85px;
}
/*
About Section
*/
#aboutSection {
  background-color: var(--color-1);
  color: var(--color-3);
  // padding-top: 50px;
  padding-bottom: 40px;
}

#aboutSection .title {
  padding-top: 25px;
  padding-bottom: 45px;
}
#aboutSection .paragraph {
  margin-bottom: 40px;
}
/*
Gallery Section
*/
#gallerySection {
  // width: 95%;
  background-color: var(--color-2);
  
}

#gallerySection .slick-track .slick-slide {
  opacity: 0.1;
  text-align: center;
  padding: 55px 35px;
}

#gallerySection .slick-track .slick-center {
  opacity: 1;
}
/*
Roadmap Section
*/
#roadmapSection {
  background-color: var(--color1);
  padding-top: 50px;
  padding-bottom: 50px;
}
#roadmapSection .title {
  color:#000;
  font-weight: bold;
}
/*
News Ticker Section
*/
#newsTickerSection {
  margin-top: 25px;
  margin-bottom: 25px;
  opacity: 0.2;
}

#timeline-chrono-wrapper .timeline-card-content {
  padding-top: 25px;
  box-shadow: 10px 10px black;
  // border-radius: 20px;
}

#timeline-chrono-wrapper .vertical-item-row .timeline-item-title {
  font-size: 40px;
  height: 70px;
  width: 70px;
  // border-radius: 35px;
  text-align: center;
}

ul.timeline-controls {
  display: none;
}

#timeline-chrono-wrapper .vertical-item-row .timeline-vertical-circle div {
  // background-color: #e0ac69;
}

#timeline-chrono-wrapper .timeline-card-content span {
  display: none;
}

#timeline-chrono-wrapper .timeline-card-content .card-description {
  overflow: visible;
  margin-bottom: 25px;
}

#timeline-chrono-wrapper .timeline-card-content .card-description p {
  // color: white;
  font-size: 18px;
  height: 100%;
}

#timeline-chrono-wrapper .timeline-card-content .card-title a {
  font-size: 22px;
  text-decoration: none;
  // color: black;
  padding-left: 20px;
}

#timeline-chrono-wrapper .timeline-card-content .card-title a:hover {
  font-size: 22px;
  text-decoration: none;
  // color: white;
  padding-left: 20px;
}
/*
FAQ Section
*/
#faqSection {
  background-color: var(--color-1);
  padding-top: 50px;
  padding-bottom: 50px;
}g
#faqSection .container {
  background-color: var(--color-3);
  color: var(--color-1);
  border-radius: 10px;
  padding-top: 25px;
  padding-bottom: 50px;
}
#faqSection .title {
  padding-bottom: 50px;
}

#faqSection .accordion-item {
  border: 0;
  background-color: var(--color-3);
  color:  var(--color-1);
}
#faqSection .accordion-item .accordion-header button {
  background-color: var(--color-3);
  color: var(--color-1);
  font-size: 25px;
  font-weight: bold;
}
#faqSection .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}


/*Footer
*/
#footer {
  font-size: 18px;
  background-color: var(--color-3);
  color: var(--color-1);
}

#footerLeft .social-link a {
  font-size: 20px;
  color: var(--color-1);
}
#footerLeft .social-link a i {
  font-size: 20px;
  color: var(--color-1);
}

#footerLeft .social-link a:nth-child(2),
a:nth-child(3) {
  padding-left: 25px;
}

#footerLeft .social-link {
  color: white;
  padding: 20px;
}
#footerRight label {
  font-size: 12px;
}
#footer .logo {
  width: 117px;
}
.logo {
  width: 117px;
}


@media screen and (max-width: 990px)  {
  #headerMainMenu .menus a {
    font-size: 18px;
  }
  .container {
    width: 100%;
  }
  #mintingSection {
    padding-top: 95px;
    padding-bottom: 25px;
  }
  #mintingSection .container .right {
    margin: 25px auto 0;
    display: none;
  }
  #mintingSection .text_center  {
    text-align: center;
  }
  #faqSection .accordion-item .accordion-header button {
    font-size: 20px;
    font-weight: bold;
  }
  #faqSection .accordion-item {
    font-size: 16px;
  }
  .css-1iv48dl-TimelineVerticalWrapper, .css-mpnodf-TimelineTitleWrapper {
    padding: 1em 0 !important;
  }
  #roadmapSection {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  #roadmapSection .container {
    width: 100%;
  }
  #roadmapSection .title {
    padding-bottom: 16px;
  }
  #timeline-chrono-wrapper .vertical-item-row .timeline-item-title {
    font-size: 18px;
    height: 36px;
    width: 36px;
    // border-radius: 20px;
    text-align: center;
    vertical-align: center;
  }
  #timeline-chrono-wrapper .timeline-card-content .card-title a {
    padding-left: 0;
    font-size: 18px;
  }
  #timeline-chrono-wrapper .timeline-card-content .card-title a:hover {
    padding-left: 0;
    font-size: 18px;
  }
  #timeline-chrono-wrapper .timeline-card-content .card-description p {
    font-size: 14px;
  }
  #footerLeft .social-link {
    text-align: center; 
  }
  #footerRight .col {
    justify-content: center;
    padding-bottom: 35px;
  }
}